import React, {useEffect} from 'react';

const Municipios = () => {
    useEffect(() => {
        document.body.className = "page-municipio";

        return () => {
            document.body.className = "";
        }
    }, []);

    return (
        <div>
            <h2>Informação não disponível</h2>
            {/*
            <iframe title="Report Section"
                width="1024"
                height="804"
                src={"https://app.powerbi.com/view?r=eyJrIjoiMmFmOTA5ZjQtOGNjNC00ZjM1LWEzMTUtYjQ4M2Q5M2EzYzhiIiwidCI6IjAxN2Q3NGQzLTU5Y2YtNDE3ZS1hOTA0LTY2MjRkMzg4NzYxNiIsImMiOjh9&pageName=ReportSectionf99476948079bcd4b53d"}
                allowFullScreen={true}
    />*/}
        </div>
        
    )
}

export default Municipios;
