import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import {
    Box,
    Checkbox, Chip,
    FormControl,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent, Typography
} from '@mui/material';
import RadioButton from '../../../common/components/RadioButton/RadioButton';
import './Diagnostico.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BarChart from '../../../common/components/BarChart/BarChart';
import PieChart from '../../../common/components/PieChart/PieChart';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

const Diagnostico = () => {
    const frameHeight = '804px';
    const inputRef = useRef() as MutableRefObject<HTMLIFrameElement>;
    const [height, setHeight] = useState(frameHeight);
    const onLoad = () => {
        if (inputRef.current !== null) {
            setHeight(frameHeight);
            // ** Blocked a frame from accessing a cross-origin frame **
            // setHeight(inputRef.current.contentWindow.document.body.scrollHeight + 'px');
            // const ref1 = inputRef.current;
            // console.log('' + ref1?.contentWindow?.document.body.scrollHeight + 'px');
        }
    };

    useEffect(() => {
        document.body.className = "page-diagnostico";

        return () => {
            document.body.className = "";
        }
    }, []);

    return (
        <>
            <iframe
                ref={inputRef}
                title="Report Section"
                width="100%"
                height={height}
                src={window.mobesc.POWER_BI_DIAGNOSTICO}
                allowFullScreen={true}
                id='powerbi-report1'
                onLoad={onLoad}
            ></iframe>
        </>
    )
}

export default Diagnostico;
