import React, {useEffect, useState} from 'react';
// import data from './data.json';
import data from './linksSolucoesMedidas.json';
import {Typography} from '@mui/material';
import parse from 'html-react-parser';
import './SolucoesMedida.scss'
import iconDiagnostic from '../../../assets/icons/diagnostic.png';
import iconSolutions from '../../../assets/icons/solution.png';
import iconCheckList from '../../../assets/icons/check-list.png';
import reparticaoModalJSON from '../../public/mobilidade-escolar/reparticao-modal-2011.json';


const SolucoesMedida = () => {
    
    useEffect(() => {
        document.body.className = "page-solucoesMedida";

        return () => {
            document.body.className = "";
        }
    }, []);


    return (
        <>
            {/*<Typography sx={{color: "#808191", fontWeight: 400, marginBottom: "20px"}}></Typography>*/}

            <section id={"section1"}>
                {
                    data && 
                    <div className={"toolkit-table"}>
                        <div className={"col col-1"}>
                            {
                                data[0].data.map((item) => (
                                    <a key={item.text} href={item.fileLink} className={`col-title`} target='_blank'>
                                        <div key={item.text} className={`col-item active`} data-id={item.id}>
                                            <Typography>{parse(item.text)}</Typography>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                        <div className={"col col-2"}>
                            {
                                data[1].data.map((item) => (
                                    <a key={item.text} href={item.fileLink} className={`col-title`} target='_blank' >
                                        <div key={item.text} className={`col-item active`} data-id={item.id}>
                                            <Typography>{parse(item.text)}</Typography>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                        <div className={"col col-2"}>
                            {
                                data[2].data.map((item) => (
                                    <a key={item.text} href={item.fileLink} className={`col-title`} target='_blank' >
                                        <div key={item.text} className={`col-item active`} data-id={item.id}>
                                            <Typography>{parse(item.text)}</Typography>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                }
            </section>
        </>
    )
}

export default SolucoesMedida;
