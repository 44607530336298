import React, {useEffect, useState} from 'react';
// import data from './data.json';
import data from './linksSolucoes.json';
import {Typography} from '@mui/material';
import parse from 'html-react-parser';
import './Solucoes.scss'
import iconDiagnostic from '../../../assets/icons/diagnostic.png';
import iconSolutions from '../../../assets/icons/solution.png';
import iconCheckList from '../../../assets/icons/check-list.png';
import reparticaoModalJSON from '../../public/mobilidade-escolar/reparticao-modal-2011.json';


const Solucoes = () => {

    useEffect(() => {
        document.body.className = "page-solucoes";

        return () => {
            document.body.className = "";
        }
    }, []);


    return (
        <>
            <Typography sx={{color: "#808191", fontWeight: 400, marginBottom: "20px"}}>Selecione o problema encontrado e disponibilizaremos soluções e fichas de ação para a sua resolução.</Typography>

            <section id={"section1"}>
                {
                    data &&
                    <div className={"toolkit-table"}>
                        <div className={"col col-1"}>
                            <div className={"col-title-wrapper"}>
                                <Typography className={"col-title"} variant={"h4"}>{parse(data[0].title)}</Typography>
                                {/*<img className={"icon"} src={iconDiagnostic} alt={data[0].title}/>*/}
                            </div>
                            {
                                data[0].data.map((item) => (
                                    <a key={item.text} href={item.fileLink} className={`col-title`} target='_blank' >
                                        <div className={`col-item active`} data-id={item.id}>
                                            <Typography>{parse(item.text)}</Typography>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                        <div className={"col col-2"}>
                            <div className={"col-title-wrapper"}>
                                <Typography className={"col-title"} variant={"h4"}>{parse(data[1].title)}</Typography>
                                {/*<img className={"icon"} src={iconDiagnostic} alt={data[1].title}/>*/}
                            </div>
                            {
                                data[1].data.map((item) => (
                                    <a key={item.text} href={item.fileLink} className={`col-title`} target='_blank' >
                                        <div className={`col-item active`} data-id={item.id}>
                                            <Typography>{parse(item.text)}</Typography>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                        <div className={"col col-3"}>
                            <div className={"col-title-wrapper"}>
                                <Typography className={"col-title"} variant={"h4"}>{parse(data[2].title)}</Typography>
                                {/*<img className={"icon"} src={iconDiagnostic} alt={data[2].title}/>*/}
                            </div>
                            {
                                data[2].data.map((item) => (
                                    <a key={item.text} href={item.fileLink} className={`col-title`} target='_blank' >
                                        <div className={`col-item active`} data-id={item.id}>
                                            <Typography>{parse(item.text)}</Typography>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                }
            </section>
            <section id={"section2"}>
                {
                    data &&
                    <div className={"toolkit-table"}>
                        <div className={"col col-1"}>
                            <div className={"col-title-wrapper"}>
                                <Typography className={"col-title"} variant={"h4"}>{parse(data[3].title)}</Typography>
                                {/*<img className={"icon"} src={iconDiagnostic} alt={data[3].title}/>*/}
                            </div>
                            {
                                data[3].data.map((item) => (
                                    <a key={item.text} href={item.fileLink} className={`col-title`} target='_blank' >
                                        <div className={`col-item active`} data-id={item.id}>
                                            <Typography>{parse(item.text)}</Typography>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                        <div className={"col col-2"}>
                            <div className={"col-title-wrapper"}>
                                <Typography className={"col-title"} variant={"h4"}>{parse(data[4].title)}</Typography>
                                {/*<img className={"icon"} src={iconDiagnostic} alt={data[4].title}/>*/}
                            </div>
                            {
                                data[4].data.map((item) => (
                                    <a key={item.text} href={item.fileLink} className={`col-title`} target='_blank' >
                                        <div className={`col-item active`} data-id={item.id}>
                                            <Typography>{parse(item.text)}</Typography>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                        <div className={"col col-1"}></div>
                    </div>
                }
            </section>
        </>
    )
}

export default Solucoes;
